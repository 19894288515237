import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RELOAD_POPUP_TRUE, RELOAD_POPUP_FALSE } from "../../redux/actionType";

const Timer = (props) => {
  let dispatch = useDispatch();
  const contractData = useSelector((state) => state.contract);
  const [remain, setRemain] = useState(0);
  const [remainFormateed, setRemainFormateed] = useState(0);
  const [timerCheck, setTimerCheck] = useState(false);
  useEffect(() => {
    let interval;
    interval = setInterval(setRemaningSlotTime, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [contractData.contractInstance, contractData.globalStage, props.countedArray]);

  useEffect(() => {
    if (remain > 0) {
      timeFormatterFunction();
    }
  }, [remain]);

  const timeFormatterFunction = () => {
    let scnds = remain % 60;
    let minutes = parseInt(remain / 60);
    let minutesActual = minutes % 60;
    let hours = parseInt(minutes / 60);
    setRemainFormateed(
      `${hours} Hours - ${minutesActual} Minutes - ${scnds} Seconds`
    );
  };

  const setRemaningSlotTime = () => {
    const startTime = contractData.startGameTime;
    if (startTime !== 0 && startTime < Math.floor(Date.now() / 1000)) {
      const slot = process.env.REACT_APP_TIMESLOT;
      const currentTime = Math.floor(Date.now() / 1000);
      let remaningTime = (currentTime - startTime) % slot;
      remaningTime = slot - remaningTime;
      console.log("IS WINNING DAY END>>> ",
      (
        remaningTime == slot - 2 &&
        contractData?.globalStage <= process.env.REACT_APP_STAGES - 1
        &&
        (contractData?.safetiles[process.env.REACT_APP_STAGES - 2] == undefined || contractData?.winningDayEnd == false)
      ),
      contractData?.globalStage,contractData?.winningDayEnd,contractData?.safetiles[process.env.REACT_APP_STAGES - 2]


      )
      if (
        remaningTime == slot - 2 &&
        contractData?.globalStage <= process.env.REACT_APP_STAGES - 1
        &&
        (contractData?.safetiles[process.env.REACT_APP_STAGES - 2] == undefined || contractData?.winningDayEnd == false)
      ) {
        if (
          (contractData?.lastJumpTime != 0 && contractData?.latestTimestampOfUsers > contractData?.gameEndDays)
          ||
          (contractData?.lastJumpTime != 0 && contractData?.gameInitializeDay > contractData?.thresHoldTime)
          ||
          (contractData.globalStage == process.env.REACT_APP_STAGES - 1 && contractData?.safetiles[process.env.REACT_APP_STAGES - 2] !== undefined
            && contractData?.winningDayEnd == true)
        ) {
          dispatch({
            type: RELOAD_POPUP_FALSE,
            payload: false,
          });
        }
        else {
          dispatch({
            type: RELOAD_POPUP_TRUE,
            payload: true,
          });
        }
      }

      setRemain(remaningTime);
    } else {

      setRemain(0);
    }
  };
  //   useEffect(()=>{
  //     noWinnerCheck();
  //   },[props.countedArray])

  //   const noWinnerCheck = async()=>{
  //    console.log("IS WINNING DAY END>>> call noWinnerCheck\\",) 

  //     if(contractData?.globalStage == process.env.REACT_APP_STAGES - 1 &&
  //       contractData.gameInitializeDay !== 0 && contractData?.safetiles[contractData?.globalStage - 1] !== undefined ){

  //     contractData?.safetiles[contractData.globalStage - 1]?.safeTile < 50 && props.countedArray[contractData.globalStage]?.leftUsers > 0 && contractData?.winningDayEnd == true ? setTimerCheck(true) : setTimerCheck(false); 
  //     contractData?.safetiles[contractData.globalStage - 1]?.safeTile >= 50 && props.countedArray[contractData.globalStage]?.rightUsers > 0 && contractData?.winningDayEnd == true ? setTimerCheck(true) : setTimerCheck(false);
  // }
  //   }

  return (
    <div className="out-timer">
      {
        (contractData.lastJumpTime != 0 &&
          contractData.latestTimestampOfUsers > contractData?.gameEndDays) ||
          (contractData.lastJumpTime != 0 &&
            contractData.gameInitializeDay > contractData?.thresHoldTime) ||
          (contractData.globalStage == process.env.REACT_APP_STAGES - 1 &&
            contractData?.safetiles[process.env.REACT_APP_STAGES - 2] !== undefined && contractData?.winningDayEnd == true)
          ? "Game Ended"
          : remain
            ? remainFormateed
            : "Game not started yet"}
    </div>
  );
};

export default Timer;
