import { React, useEffect, useState } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import { CheckMetaMaskInstalled, MetamaskConnection } from "../../redux/connectWallet/action";
import { useDispatch, useSelector } from "react-redux";
import { getLatestData } from "../../redux/connectWallet/action";
import { toast } from "react-toastify";
import { ethers } from "ethers";

import backgroundVideo from "../../assets/images/background.mp4";
import Sun from "../../assets/images/sun.png";
import Stairs from "../../assets/images/stairs.png";
import Nftfram from "../../assets/images/nft-fram.png";
import Tile12 from "../../assets/images/tile12.png";
import Tile11 from "../../assets/images/tile11.png";
import LeftIcon from "../../assets/images/left-arrow.png";
import RightIcon from "../../assets/images/right-arrow.png";
import TopIcon from "../../assets/images/top-arrow.png";
import BottomIcon from "../../assets/images/bottom-arrow.png";
import ConnectWallet from "../connectWallet/connect";
import Loader from "../Loader/loader";
import Timer from "./time";
import axios from "axios";
import SwitchSideModal from "../Modals/switchSideModal";
import RestartGameModal from "../Modals/restartGameModal";
import NftCardModal from "../Modals/nftCardModal";
import CountdownModal from "../Modals/countDownModal";
import GameDataModal from "../Modals/gameDataModal";
import MyNftModal from "../Modals/myNftModal";
import RemainingNftModal from "../Modals/remainingNftModal";
import { LOADER_STATE_FALSE, LOADER_STATE_TRUE,TRESUARY_BALANCE } from "../../redux/actionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import NftListModal from "../Modals/nftListModal";

const Banner = (props) => {
  let dispatch = useDispatch();
  const contractData = useSelector((state) => state.contract);
  const [show, setShow] = useState(false);
  const [nftListShow, setNftListShow] = useState(false);

  const [LeftMove, SetLeftMove] = useState(false);
  const [RightMove, SetRightMove] = useState(false);

  const [nftSelected, setnftSelected] = useState([]);
  const [StimeoutTrue, setStimeoutTrue] = useState(false);
  const [stages, setstages] = useState(0);
  const [sstages, setsstages] = useState(0);
  const [stair, setstair] = useState(0);
  const [current, setcurrent] = useState(0);

  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [stop, setstop] = useState(false);
  const [usersNft, setusersNft] = useState([]);
  const [userNft, setUserNft] = useState([]);
  const [leftRightModal, setleftRightModal] = useState(false);
  const [countedArray, setCountedArray] = useState([]);
  const [allNftList, setAllNftList] = useState([]);
  const [entryFeesFlag, setentryFeesFlag] = useState(false);
  const [claimDisable, setclaimDisable] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [myNfts, setMyNfts] = useState([]);
  const [firstNftSelected, setfirstNftSelected] = useState([]);
  const [switchSideModal, setSwitchSideModal] = useState(false);
  const [clickedNft, setClickedNft] = useState({});
  const [singlePlayerId, setsinglePlayerId] = useState("");
  const [restartGameModal, setRestartGameModal] = useState(false);
  const [nftCardModal, setNftCardModal] = useState(false);
  const [claim, setClaim] = useState(true);
  const [myNftModal, setmyNftModalShoww] = useState(false);
  const [gameDataModal, setGameDataModal] = useState(false);
  const [gameEndModalHandle, setGameEndModalHandle] = useState(false);
  const [isZeroNfts, setIsZeroNfts] = useState(false);
  const [congratulationShow, setCongratulationShow] = useState(false);
  const [reRender, setReRender] = useState(false);

  //On First Call
  useEffect(() => {
    dispatch(CheckMetaMaskInstalled());
    userDataForStage();
  }, []);



  useEffect(() => {
    if (contractData?.signerWallet) {
      availableNFts();
    }
  }, [contractData?.signerWallet]);


  useEffect(() => {
    if (contractData.countdownLoader == false) {
      setstages(contractData.globalStage);
    }
  }, [contractData.globalStage, contractData.countdownLoader]);

  useEffect(() => {
    setcurrent(contractData.globalStage);
  }, [contractData.globalStage]);

  useEffect(() => {
    userDataForStage();
  }, [contractData.stagesUserData, contractData.globalStage]);

  // useEffect(() => {
  //   setTileModelTimeout(true);
  // }, [contractData?.safetiles]);

  useEffect(() => {
    setstages(stages);

    if (process.env.REACT_APP_STAGES - 1 == stages) {
      setstair(12);
    } else {
      setstair(stages);
    }
  }, [stages]);

  useEffect(() => {
    window.onload = () => {
      isConnected();
    };

    async function isConnected() {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      if (accounts.length) {
        console.log(`You're connected to: ${accounts[0]}`);
        // setConnectionCheck(true)
        dispatch(MetamaskConnection())
      }
    }
  }, [])
  useEffect(() => {

    if (contractData?.contractInstance) {


      const readEvent = () => {

        contractData.contractInstance.on(
          [
            "ParticipateOfPlayerInGame",
            "ParticipateOfPlayerInBuyBackIn",
            "EntryFee",
            "ParticipatePlayerInLateBuyBackIn",
            // "RestartNFt",
            // "GameReward"
          ], (eventData) => {


            let findUser = allUsers[Number(eventData.args.stage)] !== undefined ? allUsers[Number(eventData.args.stage)].filter(item => item.playerId === eventData.args.playerId) : [];

            if (findUser.length <= 0) {

              updateStageData(eventData);
              setReRender(true);
            }
            


          }
        )
      }
      if (!reRender) {
        readEvent();
      }
    }
  }, [allUsers, reRender])

  const updateStageData = (event) => {

    if (event.event == "ParticipateOfPlayerInGame") {
      let wholeData = allUsers;
      let nftsCount = countedArray;


      wholeData[Number(event.args.stage) - 1].map((data, i) => {
        if (data.playerId == event.args.playerId) {
          // if(contractData?.globalStage !== Number(event.args.globalStage)){
          //   dispatch({
          //     type: GLOBAL_STAGE,
          //     payload: Number(event.args.globalStage),
          //   });
          // }


          if (nftsCount[Number(event.args.stage)] === undefined) {

            let rightUsers = 0;
            let leftUsers = 0;
            event.args.lastJumpSide == true ? rightUsers = rightUsers + 1 : leftUsers = leftUsers + 1;

            nftsCount[Number(event.args.stage)] = { "rightUsers": rightUsers, "leftUsers": leftUsers }

            if (data.lastJumpSide == true) {
              if (nftsCount[Number(event.args.stage) - 1].rightUsers !== 0) {
                nftsCount[Number(event.args.stage) - 1].rightUsers = nftsCount[Number(event.args.stage) - 1] - 1;
              }
            } else {

              if (nftsCount[Number(event.args.stage) - 1].leftUsers !== 0) {
                nftsCount[Number(event.args.stage) - 1].leftUsers = nftsCount[Number(event.args.stage) - 1].leftUsers - 1;
              }

            }

          }
          else {
            event.args.lastJumpSide == true ?
              nftsCount[Number(event.args.stage)].rightUsers = nftsCount[Number(event.args.stage)].rightUsers + 1
              : nftsCount[Number(event.args.stage)].leftUsers = nftsCount[Number(event.args.stage)].leftUsers + 1;

            if (data.lastJumpSide == true) {
              if (nftsCount[Number(event.args.stage) - 1].rightUsers !== 0) {
                nftsCount[Number(event.args.stage) - 1].rightUsers = nftsCount[Number(event.args.stage) - 1] - 1;
              }
            }
            else {
              if (nftsCount[Number(event.args.stage) - 1].leftUsers !== 0) {
                nftsCount[Number(event.args.stage) - 1].leftUsers = nftsCount[Number(event.args.stage) - 1].leftUsers - 1;

              }
            }
          }

          data.stage = event.args.stage;
          data.lastJumpSide = event.args.lastJumpSide;
          data.lastJumpAt = event.args.lastJumpAt;
          data.day = event.args.day;
          if (wholeData[Number(event.args.stage)] !== undefined) {


            wholeData[Number(event.args.stage)].push(data)
          }
          else {

            wholeData[Number(event.args.stage)] = [data];
          }

          let newArray = wholeData[Number(event.args.stage) - 1].filter(item => item.playerId !== event.args.playerId);
          wholeData[Number(event.args.stage) - 1] = newArray;

        }

      })
      setAllUsers([...wholeData]);
      setCountedArray([...nftsCount]);

    }
    if (event.event == "ParticipateOfPlayerInBuyBackIn") {
      liveBuyBackIn(event);
    }
    if (event.event == "EntryFee") {
      if(Number(event.args.failAt) > 0){
        liveRestartNft(event)  
      }
      else{
      liveEntryFee(event)}
    }
    if (event.event == "ParticipatePlayerInLateBuyBackIn") {
      liveLateBuyIn(event)
    }
   
  }
  const liveRestartNft = (event) => {
    const { playerId,failAt,_gameReward } = event.args;
    let wholeData = allUsers;
    // let nftsCount = countedArray;
    wholeData[Number(failAt)].map((data, i) => {
      if (data.playerId == playerId) {
        data.day = ethers.BigNumber.from("0");
        data.stage = ethers.BigNumber.from("0");
        data.lastJumpTime = ethers.BigNumber.from("0");
        data.lastJumpSide = false;
        wholeData[0].push(data)
        let newArray = wholeData[Number(failAt)].filter(item => item.playerId !== playerId);
        wholeData[Number(failAt)] = newArray;
      }
    })

              const amount = (Number(_gameReward)) / 10 ** 18;
              dispatch({
                type: TRESUARY_BALANCE,
                payload: amount ,
              });

    setAllUsers([...wholeData]);
  }

  const generateObject = async (event) => {
    const { day, lastJumpAt, lastJumpSide, nftId, nftSeries, playerId, stage, startAT, userWalletAddress } = event.args;
    const response = await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/v1/gameOne/get/metadata`,
        {
          tokenId: Number(event.args.nftId._hex),
          series:
            Number(event.args.nftSeries) == 1
              ? "seriesOne"
              : "seriesTwo",
        },
      );
    let data = {
      day,
      feeStatus: true,
      lastJumpSide,
      lastJumpTime: lastJumpAt,
      metaData: response.data.data,
      nftId,
      nftSeriestype: Number(nftSeries),
      playerId,
      stage: Number(stage),
      startAt: startAT,
      userWalletAddress
    }
    return data;
  }

  const liveLateBuyIn = async (event) => {
    const object = await generateObject(event);
    let wholeData = allUsers;
    let nftsCount = countedArray;

    event.args.lastJumpSide == true ?
      nftsCount[Number(event.args.stage)].rightUsers = nftsCount[Number(event.args.stage)].rightUsers + 1
      : nftsCount[Number(event.args.stage)].leftUsers = nftsCount[Number(event.args.stage)].leftUsers + 1;

    wholeData[Number(event.args.stage)].push(object);
    setAllUsers([...wholeData]);
    setCountedArray([...nftsCount]);
    const amount = (Number(event.args._gameReward)) / 10 ** 18;
    dispatch({
      type: TRESUARY_BALANCE,
      payload: amount ,
    });

  }

  const liveEntryFee = async (event) => {
    const object = await generateObject(event);
    let wholeData = allUsers;
    // let nftsCount = countedArray;
    if (wholeData[Number(event.args.stage)] !== undefined) {

      wholeData[Number(event.args.stage)].push(object)
    }
    else {

      wholeData[Number(event.args.stage)] = [object];
    }

    setAllUsers([...wholeData]);
    const amount = (Number(event.args._gameReward)) / 10 ** 18;
    dispatch({
      type: TRESUARY_BALANCE,
      payload: amount ,
    });

  }

  const liveBuyBackIn = (event) => {
    let wholeData = allUsers;
    let nftsCount = countedArray;

    wholeData[Number(event.args.stage) + 1].map((data, i) => {
      if (data.playerId == event.args.playerId) {


        event.args.lastJumpSide == true ?
          nftsCount[Number(event.args.stage)].rightUsers = nftsCount[Number(event.args.stage)].rightUsers + 1
          : nftsCount[Number(event.args.stage)].leftUsers = nftsCount[Number(event.args.stage)].leftUsers + 1;

        if (data.lastJumpSide == true) {
          if (nftsCount[Number(event.args.stage) + 1].rightUsers !== 0) {
            nftsCount[Number(event.args.stage) + 1].rightUsers = nftsCount[Number(event.args.stage) + 1] - 1;
          }
        }
        else {
          if (nftsCount[Number(event.args.stage) + 1].leftUsers !== 0) {
            nftsCount[Number(event.args.stage) + 1].leftUsers = nftsCount[Number(event.args.stage) + 1].leftUsers - 1;

          }
        }

        data.stage = event.args.stage;
        data.lastJumpSide = event.args.lastJumpSide;
        data.lastJumpAt = event.args.lastJumpAt;
        data.day = event.args.day;
        wholeData[Number(event.args.stage)].push(data);
        let newArray = wholeData[Number(event.args.stage) + 1].filter(item => item.playerId !== event.args.playerId);
        wholeData[Number(event.args.stage) + 1] = newArray;

      }

    });

    const amount = (Number(event.args._gameReward)) / 10 ** 18;
    dispatch({
      type: TRESUARY_BALANCE,
      payload: amount ,
    });
    setAllUsers([...wholeData]);

  }

  const handleShoww = () => {
    myNfts &&
      Object.keys(myNfts).map((i, index) => {
        {
          myNfts[i] &&
            myNfts[i].map((data, key) => {
              if (contractData?.safetiles[Number(data.stage) - 1] !== undefined) {
                if (
                  (data.lastJumpSide == true &&
                    contractData?.safetiles[Number(data.stage) - 1].safeTile >= 50) ||
                  (data.lastJumpSide == false &&
                    contractData?.safetiles[Number(data.stage) - 1].safeTile < 50)
                ) {
                  data.stageStatus = true;
                } else {
                  data.stageStatus = false;
                }
              }
            });
        }
      });
    setmyNftModalShoww(true);
  };

  const handleMyNftModalClose = () => setmyNftModalShoww(false);

  const handleNftListClose = () => {
    setnftSelected([]);
    setfirstNftSelected([]);
    setNftListShow(false);
  };

  const handleNftListShow = async () => {
    setNftListShow(true);
  };

  const handleShow = (stageNumber, side) => {
    if (stageNumber !== 0) {
      if (side === false) {
        const result = allUsers[stageNumber].filter(
          (obj) => obj.lastJumpSide == false
        );
        setAllNftList(result);
      }
      if (side === true) {
        const result = allUsers[stageNumber].filter(
          (obj) => obj.lastJumpSide == true
        );
        setAllNftList(result);
      }
    } else {
      setAllNftList(allUsers[stageNumber]);
    }
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const availableNFts = async () => {

    if (contractData?.signerWallet) {
      dispatch({
        type: LOADER_STATE_TRUE,
      });
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/v1/gameOne/existedNft?walletAddress=${contractData?.signerWallet}`
        // { walletAddress: contractData?.signerWallet }
      );
      console.log("FUNCTION CALL AVAILABLE NFTS >>>>",response);

      setusersNft(response.data.data);
      dispatch({
        type: LOADER_STATE_FALSE,
      });
    }
  };

  const participationInGame = async (direction, playerID) => {
    if (contractData?.contractInstance) {
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaa", playerID);
      let participation =
        await contractData?.contractInstance.participateInGame(
          direction,
          playerID
        );

      return participation;
    }
  };

  const back = () => {
    if (stages >= 1) {
      setstop(true);
      setstages(stages - 1);
      setstair(stair - 1);
    } else {
      setstop(false);
    }
  };

  const forward = () => {
    setstop(true);
    if (stages < process.env.REACT_APP_STAGES - 1) {
      setstages(stages + 1);
      setstair(stair + 1);
    }
  };

  const leftOrRightMove = async (direction) => {
    if (contractData?.contractInstance) {
      try {
        dispatch({
          type: LOADER_STATE_TRUE,
        });
        setleftRightModal(false);

        const side = direction == "left" ? false : true;
        if (
          contractData.latestTimestampOfUsers < 3 ||
          contractData.gameInitializeDay < 18
        ) {
          let participation = await participationInGame(side, userNft.playerId);

          let participationSuccess = await participation.wait();
          if (participationSuccess) {
            setsinglePlayerId(userNft.playerId);
            side == false ? SetLeftMove(true) : SetRightMove(true);
            dispatch(getLatestData(contractData?.contractInstance));


          } else {
            toast.error("Transaction Failed ");
          }
        } else {
          toast.error("Game is ended");
        }
      } catch (error) {
        dispatch({
          type: LOADER_STATE_FALSE,
        });
        if (error?.error?.code === 4001) {
          toast.error("Error in User denied transaction signature ");
        } else if (error?.error?.code === -32603) {
          toast.error(
            error?.error?.data
              ? error?.error?.data?.message?.split(":")[1]
              : " Transaction failed"
          );
        } else {
          toast.error("Error in User denied transaction signature ");
        }
      }
    }
  };

  const bulkNftFees = async (playerIds, NftIds, series) => {
    try {
      if (contractData.contractInstance) {
        setLoader(true);
        if (series == 2) {
          let amount = 0.01 * NftIds.length;
          amount = ethers.utils.parseEther(amount.toString());
          try {
            var approve = await contractData?.wrappedEtherInstance.approve(
              process.env.REACT_APP_CONTRACT_ADDRESS,
              amount
            );
          } catch (e) {
            // console.log("bulkNftFees ERROR>>>", e);
          }
          const approveTransaction = await approve.wait();
          if (!approveTransaction) {
            setLoader(false);
            toast.error("Approve Transaction Fail");
          }
        }
        let bulkNft = await contractData.contractInstance.bulkEntryFeeSeries(
          playerIds,
          NftIds,
          series
        );

        const transaction = await bulkNft.wait();
        if (transaction) {
          setLoader(false);
        }

        setLoader(false);
        return bulkNft;
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const userDataForStage = async () => {
    if (contractData.stagesUserData.length > 0) {
      console.log("FUNCTION CALL userDataForStage >>>>", contractData.stagesUserData);
      dispatch({
        type: LOADER_STATE_TRUE,
      });
      let allUsersArray = [];
      let userArray = [];
      let countArray = [];

      let loopPromises = [];

      contractData.stagesUserData.map((data) => {
        loopPromises.push(
          new Promise(function (resolve1, reject1) {
            let promises = [];
            for (let i = 0; i < data.stageData?.length; i++) {

              if (data.stageData[i].nftId._hex !== "0x00") {
                promises.push(
                  new Promise((resolve, reject) => {
                    // console.log("TESTING DATA?>????????1", data.stageData[i])
                    axios
                      .post(
                        `${process.env.REACT_APP_SERVER_URL}/v1/gameOne/get/metadata`,
                        {
                          tokenId: Number(data.stageData[i].nftId._hex),
                          series:
                            data.stageData[i].nftSeriestype == 1
                              ? "seriesOne"
                              : "seriesTwo",
                        },
                      )
                      .then((res) => {

                        resolve(
                          {
                            ...data.stageData[i],
                            metaData: res?.data.data
                          }
                        );
                      })
                      .catch((e) => {
                        console.log("CALL? userDataForStage", e);
                        reject(e);
                      });
                  })
                );
              }
            }
            Promise.all(promises).then((x) => {
              console.log("Check Test data", x)

              let tempUserData = [];
              let leftUsers = [];
              let rightUsers = [];
              if (x.length > 0) {
                x.forEach((item) => {
                  if (item.userWalletAddress == contractData.signerWallet) {
                    tempUserData.push(item);
                  }
                });
              }
              if (data.stageNumber !== 0) {
                leftUsers = x.filter((obj) => obj.lastJumpSide == false);
                rightUsers = x.filter((obj) => obj.lastJumpSide == true);
              }
              const testObj = {
                stage: data.stageNumber,
                usersData: x,
                userArray: tempUserData,
                countArray: {
                  rightUsers: rightUsers.length,
                  leftUsers: leftUsers.length,
                },
              };
              resolve1(testObj);
            });
          })
        )

      });

      Promise.all(loopPromises).then((x) => {
        console.log("TESTING FUNTION DATA>>>>>> 2", x);
        let zeroNftsCheck = 0;

        x.map((data) => {
          countArray[data.stage] = data.countArray;
          data.usersData?.sort((a) =>
            a.userWalletAddress == contractData?.signerWallet ? -1 : 1
          );
          allUsersArray[data.stage] = data.usersData;
          userArray[data.stage] = data.userArray;
          if (data.userArray.length > 0) {
            zeroNftsCheck = zeroNftsCheck + 1;
          }
         
        });
        if (
          contractData.globalStage == process.env.REACT_APP_STAGES - 1 &&
          contractData?.safetiles[process.env.REACT_APP_STAGES - 2] !== undefined &&
          userArray[contractData.globalStage].length > 0 &&
          contractData.gameInitializeDay !== 0
        ) {
          const safeTile = contractData?.safetiles[contractData.globalStage - 1].safeTile;
          let successNfts = false;
          userArray[contractData.globalStage].map((data, i) => {
            if (
              (data.lastJumpSide == false && safeTile < 50) ||
              (data.lastJumpSide == true && safeTile >= 50)
            ) {
              successNfts = true;
            }
          });

          if (successNfts === true) {
            console.log(
              "GAME END POP UPPPPPPP",
              contractData?.gameEndDays,
              contractData?.latestTimestampOfUsers
            );
            setClaim(true);
          } else {
            setClaim(false);
          }
        } else {
          setClaim(false);
        }
        setIsZeroNfts(zeroNftsCheck > 0 ? false : true);
        setCountedArray(countArray);
        console.log("USE EFFECT???? 4", countArray);
        setAllUsers(allUsersArray);
        setMyNfts(userArray);
        SetLeftMove(false);
        SetRightMove(false);
        dispatch({
          type: LOADER_STATE_FALSE,
        });
      });
    }
  };

  const handleSwitchSideModal = () => {
    setSwitchSideModal(true);
  };

  const closeSwitchSideModal = () => {
    setClickedNft({});
    setSwitchSideModal(false);
  };

  const handleRestartGameModal = () => {
    setRestartGameModal(true);
  };

  const closeRestartGameModal = () => {
    setRestartGameModal(false);
  };

  const handleLoader = (type) => {
    setLoader(type);
  };

  const conditionalModalHandling = (data) => {
    const stage = Number(data.stage);
    setClickedNft(data);
    if (
      contractData?.globalStage == stage &&
      stage !== 0 &&
      contractData?.safetiles[contractData?.globalStage - 1]?.safeTile == undefined
    ) {
      if (contractData.signerWallet == data.userWalletAddress) {
        handleSwitchSideModal();
      } else {
        setNftCardModal(true);
        // toast.error("Not Your Nft!")
      }
    } else {
      handleLeftRightModal(data);
    }
  };

  const handleLeftRightModal = async (data) => {
    if (contractData.signerWallet == data.userWalletAddress) {
      setstages(Number(data.stage));
      setsstages(Number(data.stage));
      if (
        contractData.globalStage > process.env.REACT_APP_STAGES - 2 &&
        Number(data.stage) > process.env.REACT_APP_STAGES - 2
      ) {
        setclaimDisable(true);
      } else {
        setleftRightModal(true);
      }
      setstop(false);
      setUserNft(data);
    } else {
      setNftCardModal(true);
      // toast.error("Not your NFT");
    }
  };

  const dataFunForTile = (arr, side) => {

    let returnData = [];
    let count = 0;
    arr.map((data) => {
      if (data.lastJumpSide == side && count < 3) {
        returnData.push(data);
        count++;
      }
    });

    return returnData;
  };

  const claimButtonFunctionality = async (playerId) => {
    if (contractData?.contractInstance) {
      try {
        setLoader(true);
        let claimReward = await contractData?.contractInstance.claimWinnerEther(
          playerId
        );
        let successClaim = await claimReward.wait();
        if (successClaim) {
          setclaimDisable(false);
          setLoader(false);
          setCongratulationShow(true);
        }
      } catch (e) {
        setLoader(false);
        toast.error(
          e?.error?.data
            ? e?.error?.data?.message?.split(":")[1]
            : " Transaction failed"
        );
      }
    }
  };

  const congratsModalClose = () => {
    setCongratulationShow(!congratulationShow);
    setLoader(true);
    dispatch(getLatestData(contractData?.contractInstance));
    setLoader(false);
  };

  const nftCardModalClose = () => {
    setClickedNft({});
    setNftCardModal(false);
  };

  const closeGameDataModal = () => {
    setGameEndModalHandle(false);
    setGameDataModal(false);
  };

  const handleGameDataModal = (data) => {
    setGameDataModal(data);
  };

  return (
    <div
      className={`game-banner-wraper ${stop ? "step" : ""} ${contractData.latestTimestampOfUsers > 3 ? "disable" : ""
        } ${current > 0 ? "stop" : ""}  ${StimeoutTrue ? "" : ""}`}
    >
      {loader === true || contractData?.loaderRedux === true ? <Loader /> : ""}
      <div className="connect-wallet">
        <div className="claim-btn">
          {contractData.signerWallet ? (
            <p>Total Game Reward : {contractData?.totalReward} ETH</p>
          ) : (
            <p>Please connect to MetaMask</p>
          )}
          {claimDisable == true ? (
            <button
              onClick={() => claimButtonFunctionality(userNft.playerId)}
              className="btn-connect claim"
            >
              Claim
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="btn-wrapper">
          {contractData.signerWallet != "" ? (<button className="btn-connect" onClick={handleShoww}>
            My NFTS
          </button>) : ""}

          {contractData.signerWallet != "" ? (<button onClick={handleNftListShow} className="btn-connect">
            NFT List{" "}
          </button>) : ""}
          {contractData.signerWallet !== "" &&
            contractData?.ownerAddress == contractData?.signerWallet ? (
            <button onClick={handleRestartGameModal} className="btn-connect">
              Restart Game{" "}
            </button>
          ) : (
            ""
          )}
          <ConnectWallet />
        </div>

        <Timer countedArray={countedArray}/>
      </div>
      {contractData?.reloadPopUp ? (
        <div className="timeout-error">
          <p>Your Game Data is out Dated. Please Reload your Game. </p>
          <button
            className="btn btn-white"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="bridge-background">
        <video muted autoPlay loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="sun-image">
        <Image className="rotate" src={Sun} fluid />
      </div>
      <div
        className={`stairs-image stage-${stair} ${stages >= process.env.REACT_APP_STAGES - 1 ? "show" : ""
          }`}
      >
        <Image src={Stairs} fluid />
      </div>
      <div className="stage-title">Stage {stages}</div>
      <div className="titles-wrapper">
        {/* Tile 1 */}

        {(() => {
          const arr = [];
          for (let i = process.env.REACT_APP_STAGES; i >= 2; i--) {

            arr.push(
              <div
                className={`tile-row  ${sstages === i ? "stay" : ""} ${current >= i ? "current" : ""
                  } tile-row${i - stages}-image`}
              >
                <div className="slow-up-down">
                  <div className="d-flex justify-content-between">
                    <div
                      className={`tile left-tile ${contractData?.safetiles[i - 2]?.safeTile !== undefined &&
                        contractData?.safetiles[i - 2]?.safeTile >= 50
                        ? contractData.countdownLoader == false &&
                          gameDataModal == false
                          ? "drop-left-tile drop-left-tile-no-anim"
                          : contractData.globalStage != i - 1
                            ? "drop-left-tile"
                            : ""
                        : ""
                        }`}
                    >
                      <Image src={Tile12} fluid />

                      <div className="current-all-nfts d-flex align-items-end">
                        {countedArray[i - 1]?.leftUsers > 3 ? (
                          <button
                            className="more-button"
                            onClick={() => handleShow(i - 1, false)}
                          >
                            <span className="fa fa-bars"></span>
                            <span className="number">
                              {countedArray[i - 1]?.leftUsers}
                            </span>
                          </button>
                        ) : (
                          ""
                        )}
                        <div className="related-nft">
                          {allUsers[i - 1] &&
                            dataFunForTile(allUsers[i - 1], false).map(
                              (dataOfUser, key) => {
                                return dataOfUser.lastJumpSide == false ? (
                                  <>
                                    <div
                                      key={key}
                                      className={`nft-fram ${singlePlayerId != "" &&
                                        singlePlayerId == dataOfUser.playerId
                                        ? ` ${LeftMove ? `left-move ` : ""
                                        }  ${RightMove ? `right-move ` : ""
                                        }`
                                        : ""
                                        } `}
                                    >
                                      {Number(dataOfUser.day) >=
                                        Math.floor(
                                          contractData?.gameInitializeDay
                                        ) ? (
                                        <div className="moved-icon">
                                          <span className="icon">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faArrowAltCircleUp}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {dataOfUser.lastJumpSide == false ? (
                                        <Image
                                          onClick={() =>
                                            conditionalModalHandling(dataOfUser)
                                          }
                                          src={
                                            dataOfUser.metaData.imageUrl
                                              ? dataOfUser.metaData.imageUrl
                                              : Nftfram
                                          }
                                          fluid
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tile right-tile ${contractData?.safetiles[i - 2]?.safeTile < 50 &&
                        contractData?.safetiles[i - 2]?.safeTile !== undefined
                        ? contractData.countdownLoader == false &&
                          gameDataModal == false
                          ? "drop-right-tile drop-right-tile-no-anim"
                          : contractData.globalStage != i - 1
                            ? "drop-right-tile"
                            : ""
                        : ""
                        }`}
                    >
                      <Image src={Tile11} fluid />
                      <div className="current-all-nfts d-flex align-items-end">
                        {countedArray[i - 1]?.rightUsers > 3 ? (
                          <button
                            className="more-button"
                            onClick={() => handleShow(i - 1, true)}
                          >
                            <span className="fa fa-bars"></span>
                            <span className="number">
                              {countedArray[i - 1]?.rightUsers}
                            </span>
                          </button>
                        ) : (
                          ""
                        )}
                        <div className="related-nft">
                          {allUsers[i - 1] &&
                            dataFunForTile(allUsers[i - 1], true).map(
                              (dataOfUser, key) => {

                                return (
                                  <>
                                    <div
                                      key={key}
                                      className={`nft-fram ${singlePlayerId != "" &&
                                        singlePlayerId == dataOfUser.playerId
                                        ? ` ${LeftMove ? `left-move ` : ""
                                        }  ${RightMove ? `right-move ` : ""
                                        }`
                                        : ""
                                        } `}
                                    >
                                      {Number(dataOfUser.day) >=
                                        Math.floor(
                                          contractData?.gameInitializeDay
                                        ) ? (
                                        <div className="moved-icon">
                                          <span className="icon">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faArrowAltCircleUp}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {dataOfUser.lastJumpSide == true ? (
                                        <Image
                                          onClick={() =>
                                            conditionalModalHandling(dataOfUser)
                                          }
                                          src={
                                            dataOfUser.metaData.imageUrl
                                              ? dataOfUser.metaData.imageUrl
                                              : Nftfram
                                          }
                                          fluid
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return arr;
        })()}

        <div
          className={`tile-row ${sstages === 0 ? "stay" : ""} base ${current >= 0 ? "current" : ""
            } tile-row${1 - stages}-image`}
        >
          <div className="up-down">
            <div className="d-flex justify-content-around">
              <div className="tile right-tile">
                <Image src={Tile11} fluid />
                <div className="current-all-nfts d-flex align-items-end">
                  {allUsers[0]?.length > 3 ? (
                    <button
                      className="more-button"
                      onClick={() => handleShow(0)}
                    >
                      <span className="fa fa-bars"></span>
                      <span className="number">{allUsers[0]?.length}</span>
                    </button>
                  ) : (
                    ""
                  )}
                  <div className="related-nft">
                    {allUsers[0] &&
                      allUsers[0].map((dataOfUser, key) => {
                        return key <= 2 ? (
                          <>
                            <div
                              key={key}
                              className={`nft-fram ${singlePlayerId != "" &&
                                singlePlayerId == dataOfUser.playerId
                                ? ` ${LeftMove ? `left-move ` : ""}  ${RightMove ? `right-move ` : ""
                                }`
                                : ""
                                } `}
                            >
                              <Image
                                onClick={() =>
                                  conditionalModalHandling(dataOfUser)
                                }
                                src={
                                  dataOfUser.metaData.imageUrl
                                    ? dataOfUser.metaData.imageUrl
                                    : Nftfram
                                }
                                fluid
                              />

                            </div>
                          </>
                        ) : (
                          ""
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="move-btns">
        <div className="">
          <button className="back-btn slow-up-down " onClick={back}>
            <img src={BottomIcon} alt="Bottom Icon" className="img-fluid" />
          </button>
          <button className="forward-btn slow-up-down mb-3" onClick={forward}>
            <img src={TopIcon} alt="Top Icon" className="img-fluid" />
          </button>
        </div>
      </div>
      {leftRightModal === true ? (
        <div className="time-modal">
          <button
            type="button"
            className="close"
            onClick={() => setleftRightModal(!leftRightModal)}
          ></button>
          <div className="custom-modal-header">Select Tile For Jump </div>
          <div className="custom-modal-content">
            <div className="arrow-wrapper d-flex align-items-center justify-content-between">
              {contractData?.safetiles[Number(userNft.stage)] !== undefined &&
                contractData?.safetiles[Number(userNft.stage)].safeTile >= 50 ? (
                <div className="arrow-icon right-icon"></div>
              ) : (
                <div
                  className="arrow-icon left-icon"
                  onClick={() => leftOrRightMove("left")}
                >
                  <img src={LeftIcon} alt="icon left" className="img-fluid" />
                </div>
              )}
              {contractData?.safetiles[Number(userNft.stage)] !== undefined &&
                contractData?.safetiles[Number(userNft.stage)].safeTile < 50 ? (
                <div className="arrow-icon right-icon"></div>
              ) : (
                <div
                  className="arrow-icon right-icon"
                  onClick={() => leftOrRightMove("right")}
                >
                  <img src={RightIcon} alt="icon right" className="img-fluid" />
                </div>
              )}
            </div>
            {/* <button className="btn-connect">Stay</button> */}
          </div>
        </div>
      ) : (
        ""
      )}


      {contractData?.signerWallet !== "" &&
        contractData?.ownerAddress !== contractData?.signerWallet ? (
        (contractData?.lastJumpTime != 0 &&
          contractData.countdownLoader == false &&
          gameDataModal == false &&
          contractData?.latestTimestampOfUsers > contractData?.gameEndDays &&
          claim == false) ||
          (contractData?.lastJumpTime != 0 &&
            contractData.countdownLoader == false &&
            gameDataModal == false &&
            contractData?.gameInitializeDay > contractData?.thresHoldTime &&
            claim == false) ||
          (contractData.globalStage == process.env.REACT_APP_STAGES - 1 &&
            contractData.countdownLoader == false &&
            gameDataModal == false &&
            contractData?.safetiles[process.env.REACT_APP_STAGES - 2] !== undefined &&
            gameEndModalHandle == false &&
            claim == false && contractData?.winningDayEnd == true) ? (
          <>
            {console.log("CLAIM CHECK>>>>>>>", claim)}
            <>
              <div className="game-over-bg"></div>
              <div className="time-modal">
                <div className="custom-modal-content pt-0">
                  <h1 className="text-center my-5">Game Ended</h1>
                </div>
              </div>
            </>
          </>
        ) : (
          <>{console.log("CLAIM CHECK>>>>>>>", claim)}</>
        )
      ) : (
        ""
      )}
      {congratulationShow ? (
        <>
          <div className="game-over-bg"></div>
          <div className="time-modal">
            <button
              type="button"
              className="close"
              onClick={congratsModalClose}
            ></button>
            <div className="custom-modal-content pt-0">
              <h2 className="text-center my-2">CONGRATULATIONS  <br /> YOU'VE WON !</h2>
              {/* <p>YOU'VE WON {(claimAmount > 0) ? claimAmount : winnerReward} ETH REWARD AMOUNT"</p> */}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <Modal className="nft-modal" show={show} onHide={handleClose}>
        <Modal.Header className="justify-content-center">
          <Modal.Title>Players List</Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          {allNftList?.map((data, key) => {
            return (
              <div
                key={key}
                className="nft-content-wrapper d-flex align-items-center"
                onClick={() => {
                  handleClose();
                  conditionalModalHandling(data);
                }}
              >
                <div className="nft-img">
                  <Image
                    src={
                      data.metaData.imageUrl ? data.metaData.imageUrl : Nftfram
                    }
                    fluid
                  />
                </div>
                <div className="nft-content">
                  <h3>NFT ID :{Number(data.nftId)}</h3>
                  <h4>Stage :{Number(data.stage)}</h4>
                </div>
                <div className="address">
                  <h4>
                    <span>{data.userWalletAddress}</span>
                  </h4>
                </div>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>


   

      {contractData.countdownLoader === true &&
        contractData?.loaderRedux === false ? (
        <CountdownModal
          gameDataModalShow={(data) => {
            handleGameDataModal(data);
          }}
          gameEndModalHandler={() => {
            setGameEndModalHandle(true);
          }}
        />
      ) : (
        ""
      )}

      {contractData.globalStage == process.env.REACT_APP_STAGES - 1 &&
        contractData?.safetiles[process.env.REACT_APP_STAGES - 2] !==
        undefined ? (
        ""
      ) : contractData.remainingNftLoader === true ? (
        <RemainingNftModal allUsers={allUsers} />
      ) : (
        ""
      )}

      <MyNftModal
        show={myNftModal}
        close={() => handleMyNftModalClose()}
        myNfts={myNfts}
        conditionalModalHandling={(data) => {
          conditionalModalHandling(data);
        }}
        lodaer={(type) => handleLoader(type)}
        // calculateBuyBackIn={calculateBuyBackIn}
        zeroNftCheck={isZeroNfts}
      />
      <SwitchSideModal
        show={switchSideModal}
        close={() => closeSwitchSideModal()}
        nftData={clickedNft}
        lodaer={(type) => handleLoader(type)}
      />
      <RestartGameModal
        show={restartGameModal}
        close={() => closeRestartGameModal()}
        lodaer={(type) => handleLoader(type)}
      />
      <NftCardModal
        show={nftCardModal}
        close={() => {
          nftCardModalClose();
        }}
        nftData={clickedNft}
      />
      <GameDataModal
        show={gameDataModal}
        close={() => closeGameDataModal()}
        allUsers={allUsers}
        myNfts={myNfts}
        countedArray={countedArray}
        lodaer={(type) => handleLoader(type)}
      />

      <NftListModal
        show={nftListShow}
        close={() => handleNftListClose()}
        lodaer={(type) => handleLoader(type)}
        myNftData={usersNft}
        // calculateBuyBackIn={calculateBuyBackIn}
        availableNFts={availableNFts}
        safeSides={contractData?.safetiles}
      />
    </div>
  );
};

export default Banner;
